import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import SignatureCanvas from 'react-signature-canvas'
import Webcam from 'react-webcam';
import { errorInOperation } from '../kiosklog/ServerLogging';
import { Carousel } from 'react-responsive-carousel';
import { strings } from '../actions/string';
import { ExternalCss } from '../components/ExternalCss';
export const Signature=()=>{
    const location = useLocation();
    const routes=location.state.site.routes;
    const navigate= useNavigate();
    const [businesses,setBusinesses]=useState(null as any)
    const [showSmiley,setshowSmiley]=useState(true)
    const [site,setsite]=useState(null as any)
    const [restartCheckIn,setrestartCheckIn]=useState(false)
    const [language,setlanguage]=useState(location.state.language)
    const [acceptWaiver,setacceptWaiver]=useState(false)
    const [signaturePad,setsignaturePad] =useState(null as any);
    const [signature,setsignature]=useState(null as any);
    const [mugshot,setmugshot]=useState(null as any)
    const webcamRef = React.useRef(null as any);
    const [advInArr,setadvInArr]=useState([] as any);
    const [advOutArr,setadvOutArr]=useState([] as any);
    const languages  =location.state.language==='en' ? strings.en : strings.esp;
    const [randomSmileyText,setrandomSmileyText]=useState('');
    const capture = React.useCallback(async() => {
        try {
          const imageSrc = webcamRef.current.getScreenshot();  
          setmugshot(imageSrc)                  
        } catch (error) {
          setmugshot(null)  
        }
     }, [webcamRef]
    );

    useEffect(()=>{
      if(businesses===null){
      didMount()
      }
      try {
      if(mugshot||signature){
console.log('alert');
      }        
    } catch (error) {
        console.log(error);
        
    }

    },[mugshot,signature])

   const didMount=(()=>{

      setBusinesses(location.state.business)            
      setsite(location.state.site)   
      if(routes.signature.enabled===false || (routes.signature.alwaysShow===false && location.state.user!==false)){
        NextfinishButton()
      } 
      const site = location.state.site;
      if(advInArr.length<=0){
      const  advInArr = [];
      
      if(site.kioskUiSigInAdvFile1!==undefined && site.kioskUiSigInAdvFile1!==null && site.kioskUiSigInAdvFile1!==''){ advInArr.push(site.kioskUiSigInAdvFile1) }
      if(site.kioskUiSigInAdvFile2!==undefined && site.kioskUiSigInAdvFile2!==null && site.kioskUiSigInAdvFile2!==''){ advInArr.push(site.kioskUiSigInAdvFile2) }
      if(site.kioskUiSigInAdvFile3!==undefined && site.kioskUiSigInAdvFile3!==null && site.kioskUiSigInAdvFile3!==''){ advInArr.push(site.kioskUiSigInAdvFile3) }
      if(site.kioskUiSigInAdvFile4!==undefined && site.kioskUiSigInAdvFile4!==null && site.kioskUiSigInAdvFile4!==''){ advInArr.push(site.kioskUiSigInAdvFile4)}
      if(site.kioskUiSigInAdvFile5!==undefined && site.kioskUiSigInAdvFile5!==null && site.kioskUiSigInAdvFile5!==''){ advInArr.push(site.kioskUiSigInAdvFile5)}
      if(site.kioskUiSigInAdvFile6!==undefined && site.kioskUiSigInAdvFile6!==null && site.kioskUiSigInAdvFile6!==''){ advInArr.push(site.kioskUiSigInAdvFile6)}
      if(site.kioskUiSigInAdvFile7!==undefined && site.kioskUiSigInAdvFile7!==null && site.kioskUiSigInAdvFile7!==''){ advInArr.push(site.kioskUiSigInAdvFile7)}
      if(site.kioskUiSigInAdvFile8!==undefined && site.kioskUiSigInAdvFile8!==null && site.kioskUiSigInAdvFile8!==''){ advInArr.push(site.kioskUiSigInAdvFile8)}
      if(site.kioskUiSigInAdvFile9!==undefined && site.kioskUiSigInAdvFile9!==null && site.kioskUiSigInAdvFile9!==''){ advInArr.push(site.kioskUiSigInAdvFile9)}
      if(site.kioskUiSigInAdvFile10!==undefined && site.kioskUiSigInAdvFile10!==null && site.kioskUiSigInAdvFile10!==''){ advInArr.push(site.kioskUiSigInAdvFile10)}                
      setadvInArr(advInArr)
      }
      if(advOutArr.length<=0){
      const  advOutArr = [];
      const site = location.state.site;
      if(site.kioskUiSigOutAdvFile1!==undefined && site.kioskUiSigOutAdvFile1!==null && site.kioskUiSigOutAdvFile1!==''){ advOutArr.push(site.kioskUiSigOutAdvFile1) }
      if(site.kioskUiSigOutAdvFile2!==undefined && site.kioskUiSigOutAdvFile2!==null && site.kioskUiSigOutAdvFile2!==''){ advOutArr.push(site.kioskUiSigOutAdvFile2) }
      if(site.kioskUiSigOutAdvFile3!==undefined && site.kioskUiSigOutAdvFile3!==null && site.kioskUiSigOutAdvFile3!==''){ advOutArr.push(site.kioskUiSigOutAdvFile3) }
      if(site.kioskUiSigOutAdvFile4!==undefined && site.kioskUiSigOutAdvFile4!==null && site.kioskUiSigOutAdvFile4!==''){ advOutArr.push(site.kioskUiSigOutAdvFile4)}
      if(site.kioskUiSigOutAdvFile5!==undefined && site.kioskUiSigOutAdvFile5!==null && site.kioskUiSigOutAdvFile5!==''){ advOutArr.push(site.kioskUiSigOutAdvFile5)}
      if(site.kioskUiSigOutAdvFile6!==undefined && site.kioskUiSigOutAdvFile6!==null && site.kioskUiSigOutAdvFile6!==''){ advOutArr.push(site.kioskUiSigOutAdvFile6)}
      if(site.kioskUiSigOutAdvFile7!==undefined && site.kioskUiSigOutAdvFile7!==null && site.kioskUiSigOutAdvFile7!==''){ advOutArr.push(site.kioskUiSigOutAdvFile7)}
      if(site.kioskUiSigOutAdvFile8!==undefined && site.kioskUiSigOutAdvFile8!==null && site.kioskUiSigOutAdvFile8!==''){ advOutArr.push(site.kioskUiSigOutAdvFile8)}
      if(site.kioskUiSigOutAdvFile9!==undefined && site.kioskUiSigOutAdvFile9!==null && site.kioskUiSigOutAdvFile9!==''){ advOutArr.push(site.kioskUiSigOutAdvFile9)}
      if(site.kioskUiSigOutAdvFile10!==undefined && site.kioskUiSigOutAdvFile10!==null && site.kioskUiSigOutAdvFile10!==''){ advOutArr.push(site.kioskUiSigOutAdvFile10)}
      setadvOutArr(advOutArr)
      }


      let smileyTexts = [];
      const emoji = location.state.business.businessId==='yk4TqX7smWGlQ5tbWhhr' || location.state.business.businessId==='eiAIgty2Q8pTWaxzLoAr' ? '' : '😊';
      if (location.state.language === "en") {
        smileyTexts = [
          "Smile For The Picture "+emoji,
          "Say Cheese For The Camera! "+emoji,
          "Look Serious For The Picture (No Smiling Allowed)",
        ];
      } else {
        smileyTexts = [
          "Sonríe por la foto "+emoji,
          "Di queso para la cámara! "+emoji,
          "Mira serio para la foto (no se permite sonreír)",
        ];
      }
  
      const randomSmileyText = smileyTexts[Math.floor(Math.random() * 3)];
      setrandomSmileyText(randomSmileyText)



    })

    const AcceptWaiver=async()=>{
      setacceptWaiver(true)
    }
    const signatureShot=async(x:any)=>{
        if(signaturePad!==null){        
        const signature = signaturePad.getTrimmedCanvas().toDataURL();
        setsignature(signature)     
        if(routes.signature.takeMugshot!==undefined && routes.signature.takeMugshot===true){
          if(mugshot===null){
            let time = 500;
              if(window.location.href.includes('henderson')){
                time=900;
              }
            setTimeout(() => {
              capture()
           }, time);        
          }
        }
        }
    }

    const NextfinishButton=()=>{
        const site = location.state.site;
        const business = location.state.business;
        const user = location.state.user;
        const firstName= location.state.firstName;
        const lastName=location.state.lastName;
        const mobile =location.state.mobile;
        const zip=location.state.zip;
        const dateofbirth= location.state.dateofbirth;
        const vehicles=location.state.vehicles;
        const lang=location.state.language;
        const optOut=location.state.optOut;
        const path = '/'+business.path+'/'+site.path+'/customertype'        
        navigate(path,{state:{mobile,user,business,site,firstName,lastName,zip,dateofbirth,vehicles,mugshot,signature,language:lang,optOut,IsUpdating:false}})       
    }

    const restartCheckInfun=async()=>{
        setrestartCheckIn(true)
        const  error = { 
               associated_data:JSON.stringify('restart checkIn from signature screen'),
               class_name:'vehicle',
               error_message:'restart checkIn',
               error_type:'component error',
               exception_message:JSON.stringify('restart checkIn'),
               file_name:'signature.tsx',
               function_name:'signature-Component',
               }
         const errorInOperationx = await errorInOperation(error);
         if(errorInOperationx){
         const path = '/'+businesses.path+'/'+site.path  
         navigate(path,{state:{business:location.state.business,site:location.state.site}})
           }
   }
    
    const videoConstraints = {
        facingMode: "user",
      };

 
    return(
        businesses!==null && site!==null ?<div  className='main-container' style={{background:site.kioskUiBackgroundColor!==undefined &&   site.kioskUiBackgroundColor!==null &&  site.kioskUiBackgroundColor!=='' ? site.kioskUiBackgroundColor :  businesses.theme.background ,height:'100vh',display:'flex'}}>
            <div className="">    
            <ExternalCss/>          
                <button  disabled={restartCheckIn} style={{background:site.kioskUiSigButtonErrorColor!==undefined && site.kioskUiSigButtonErrorColor!==null && site.kioskUiSigButtonErrorColor!=='' ? site.kioskUiSigButtonErrorColor : '#c70000'}} onClick={()=>restartCheckInfun()} className='optoutbtn restartCheckinButton' >{restartCheckIn ? <ScaleLoader  width={10} height={15} color="#fff" /> :languages.restartCheckIn}</button>
            </div>  

     {!acceptWaiver ?  
     <div  className="css-19ogqkc" style={{padding:'0px',border:'none'}}>
      
      {/* ijoi */}
     <div className="css-19ogqkc">


                {language==='en' ? <button className="css-q3non2" onClick={()=>setlanguage('esp')}>Cambiar a Espanol</button>: <button className="css-q3non2" onClick={()=>setlanguage('en')}>Change to English</button>}  
                <div className='css-6p4mt6' dangerouslySetInnerHTML={{ __html: language==='en' ? site.routes.signature.waiverText : site.routes.signature.waiverTextEs }}></div>
                <p className='tac'>{language!=='en' ? 'Al presionar a continuación, reconozco que he leído, entiendo y cumpliré con lo anterior.':'By pressing below, I acknowledge I have read, understand, and will comply with the above.'}</p>
                <button   
                style={{background:site.kioskUiSigButtonSuccessColor!==undefined && site.kioskUiSigButtonSuccessColor!==null && site.kioskUiSigButtonErrorColor!=='' ? site.kioskUiSigButtonSuccessColor : 'rgb(70, 127, 30)'}} 
                onClick={()=>AcceptWaiver()} className='nextbuttoncssc' 
                >{languages.iaccept}</button>
        </div></div>
        :
        <div style={{margin:'auto'}}>

        <h1 className="enternameTitle  wilbertenternameTitle" style={{marginBottom:'4px', color:site.kioskUiTitleTextColor!==undefined && site.kioskUiTitleTextColor!==null && site.kioskUiTitleTextColor!=='' ? site.kioskUiTitleTextColor :'rgb(252, 173, 20)'}}>
          {!site.kioskUiliability ? languages.pleasesignbelow : languages.pleaseSign_liability}
          </h1>

          {site.kioskUiShowSigSmiley && showSmiley && (
                <div style={{ margin: "10px 0" }} ><h1 className="enternameTitle1 wilbertenternameTitle" style={{color:location.state.business.businessId==='yk4TqX7smWGlQ5tbWhhr' || location.state.business.businessId==='eiAIgty2Q8pTWaxzLoAr' ? '#f27537' : site.kioskUiTitleTextColor!==undefined ? site.kioskUiTitleTextColor :'rgb(252, 173, 20)' }}>
{randomSmileyText}
</h1></div>
              )}

        {site.kioskUiSigHideAdvIn!==undefined && site.kioskUiSigHideAdvIn===false?  <div className='kioskUiInAdvFilesignature'>
                      <Carousel showThumbs={false} interval={site.kioskUiAdvDelaySec ? Number(site.kioskUiAdvDelaySec+'000') : 7000} showIndicators={false} showArrows={false} autoPlay={true} showStatus={false} infiniteLoop={true}>              
                      {advInArr.map((c:any,i:any)=>(
                          <div key={i}><img src={c}/></div>
                         ))}
                     </Carousel>
              </div>:''}

              {site.kioskUiSigHideAdvOut!==undefined && site.kioskUiSigHideAdvOut===false ?
                <div className='kioskUiOutAdvFilesignature'>
                      <Carousel  showThumbs={false} interval={site.kioskUiAdvDelaySec ? Number(site.kioskUiAdvDelaySec+'000') : 7000} showIndicators={false} showArrows={false}  autoPlay={true}  showStatus={false}  infiniteLoop={true}>              
                         {advOutArr.map((c:any,x:any)=>(
                          <div key={x}><img src={c}/></div>
                         ))}

                     </Carousel>
              </div>:''}
    

              {routes.signature.takeMugshot!==undefined && routes.signature.takeMugshot===true ? 
       
       <div 
       className='positionAbsolute'
        style={{display:'math'}}>
            <Webcam     
             capture={true}     
              audio={false}
              height={300}
              ref={webcamRef}
              screenshotFormat="image/jpeg"            
              width={300}
              videoConstraints={{
                height: 400,
                width: 400
              }}             
            /></div>
            
            :''} 
        <SignatureCanvas  dotSize={6} penColor='black'   velocityFilterWeight={0} canvasProps={{width: 600, height: 300, className: 'sigCanvas'}} backgroundColor='white'
         onEnd={(e)=>signatureShot(e)} ref={(ref:any) =>setsignaturePad(ref)} />
        <br/><br/><br/>
            <div style={{display:'flex'}} className="centerDivName">
            {routes.signature.optional===true ? <button   style={{background:site.kioskUiSigButtonErrorColor!==undefined && site.kioskUiSigButtonErrorColor!==null && site.kioskUiSigButtonErrorColor!=='' ? site.kioskUiSigButtonErrorColor : '#c70000'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{languages.NoThanks}</button>:''}
                
                    <button  disabled={signature===null ||(routes.signature.takeMugshot!==undefined && routes.signature.takeMugshot===true && mugshot===null) } style={{background:site.kioskUiSigButtonSuccessColor!==undefined && site.kioskUiSigButtonSuccessColor!==null && site.kioskUiSigButtonSuccessColor!=='' ? site.kioskUiSigButtonSuccessColor : 'rgb(70, 127, 30)'}} onClick={()=>NextfinishButton()} className='nextbuttoncss' >{languages.NextFinish}</button>
                    </div>
        </div>
        }
</div>
:<div>Loading....</div>

    )
}